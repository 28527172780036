import React from "react";
import cx from "classnames";
import { Carousel } from "src/components/carousel";
import styles from "./intro-carousel.module.css";

export const AboutIntroCarousel = ({ images }) => {
  return (
    <div className={styles.introCarousel}>
      <div className={styles.carouselContainer}>
        <Carousel slides={images} />
      </div>
    </div>
  );
};
