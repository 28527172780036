import React, { useState, useEffect, useCallback, useRef } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { Image } from "src/components/image";
import { BlockContent } from "src/components/block-content";
import { Carousel } from "src/components/carousel";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useSetActiveTags } from "src/context/siteContext";

import { ReactComponent as ArrowRight } from "src/images/svg/ArrowRight.svg";

import styles from "./team.module.css";

const Bio = ({ bio }) => {
  const setActiveTags = useSetActiveTags();
  const windowSize = useWindowSize();

  const [primaryBioHeight, setPrimaryBioHeight] = useState(0);
  const [secondaryBioHeight, setSecondaryBioHeight] = useState(0);
  const [showSecondaryBio, setShowSecondaryBio] = useState(false);

  const primaryBio = useRef(null);
  const secondaryBio = useRef(null);

  useEffect(() => {
    setPrimaryBioHeight(primaryBio.current.getBoundingClientRect().height);
    setSecondaryBioHeight(secondaryBio.current.getBoundingClientRect().height);
  }, [primaryBio, secondaryBio, windowSize]);

  const bioVariants = {
    primary: { height: primaryBioHeight, overflow: `hidden` },
    secondary: { height: secondaryBioHeight, overflow: `hidden` },
  };

  const joinedAffiliatedArtists =
    bio.affiliatedArtists &&
    bio.affiliatedArtists.length > 0 &&
    bio.affiliatedArtists.map(artist => artist.slug.current).join("+");

  return (
    <div className={styles.bio}>
      <div className={styles.bioHeader}>
        {bio.portrait?.asset && (
          <Image
            className={styles.bioPortrait}
            imageId={bio.portrait.asset._id}
            width={192}
            height={192}
            objectFit="cover"
            style={{ height: `100%` }}
            imgStyle={{ height: `100%` }}
          />
        )}
        <div className={styles.bioHeaderCopy}>
          <h3 className={cx("subheading1")}>{bio.name}</h3>
          <p className={cx("body", styles.bioRole)}>{bio.role}</p>
          {bio.pronouns?.length > 0 && (
            <p className={cx("body italic", styles.bioPronouns)}>
              Pronouns {bio.pronouns.join("/")}
            </p>
          )}
        </div>
      </div>
      <div
        className={cx(
          styles.bioCopy,
          showSecondaryBio ? styles.showSecondaryBio : ""
        )}
      >
        <motion.div
          className={styles.bioCopyInner}
          animate={showSecondaryBio ? "secondary" : "primary"}
          variants={bioVariants}
        >
          <div className={styles.bioCopySecondary}>
            <div ref={secondaryBio}>
              <BlockContent blocks={bio.additionalBio} />
              {bio.featuredProjects?.length && (
                <div className={styles.featuredProjects}>
                  <h4 className={cx("subheading2 mb1")}>Discography</h4>
                  <ul className={styles.featuredProjectList}>
                    {bio.featuredProjects.map(featuredProject => (
                      <li
                        className={styles.featuredProjectItem}
                        key={featuredProject._key}
                      >
                        <Link
                          to={`/work/${featuredProject.project.content.main.slug.current}`}
                          className={cx(
                            "subheading3",
                            styles.featuredProjectLink
                          )}
                        >
                          {featuredProject.project.content.main.title}
                        </Link>
                        <span>{featuredProject.role}</span>
                      </li>
                    ))}
                  </ul>
                  {joinedAffiliatedArtists && (
                    <Link
                      className={cx("subheading3", styles.viewAllLink)}
                      to={`/work/tagged/${joinedAffiliatedArtists}`}
                      onClick={() => setActiveTags([...bio.affiliatedArtists])}
                    >
                      View all projects
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.bioCopyPrimary}>
            <div ref={primaryBio}>
              <BlockContent blocks={bio.bio} />
            </div>
          </div>
        </motion.div>
        {bio.additionalBio && (
          <button
            className={cx("reset", styles.toggleBioButton)}
            onClick={() => setShowSecondaryBio(!showSecondaryBio)}
          >
            <span className="caption1">
              {bio.nickname ? bio.nickname : bio.name.split(" ")[0]}{" "}
              {showSecondaryBio ? "Center Stage" : "Behind the Scenes"}
            </span>
            <ArrowRight className={styles.arrow} />
          </button>
        )}
      </div>
    </div>
  );
};

export const AboutTeam = ({ team }) => {
  return (
    <div className={styles.team}>
      <div className={styles.intro}>
        <div className={styles.introInner}>
          <h2 className={cx("heading2", styles.title)}>{team.title}</h2>
          <BlockContent
            className={cx("body2", styles.introCopy)}
            blocks={team.introCopy}
          />
          <div className={styles.teamCarouselContainer}>
            <Carousel slides={team.slideshow} className={styles.teamCarousel} />
          </div>
        </div>
      </div>
      <div className={styles.bios}>
        {team.bios?.length &&
          team.bios.map(bio => <Bio key={bio._id} bio={bio} />)}
      </div>
    </div>
  );
};
