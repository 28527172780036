import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { BlockContent } from "src/components/block-content";
import { useSetActiveTags } from "src/context/siteContext";

import styles from "./how-what.module.css";

export const AboutHowWhat = ({
  how,
  what,
}) => {
  const setActiveTags = useSetActiveTags();

  return (
    <div className={styles.howWhat}>
      <div className={styles.how}>
        <h2 className={cx("heading2", styles.title)}>{how.title}</h2>
        <BlockContent
          className={styles.howDefinition}
          blocks={how.definition}
        />
        <BlockContent className={cx("body2")} blocks={how.copy} />
      </div>
      <div className={styles.what}>
        <h2 className={cx("heading2", styles.title)}>{what.title}</h2>
        <BlockContent blocks={what.introCopy} />
        <div className={styles.capabilities}>
          {what.capabilities?.length &&
            what.capabilities.map(capability => (
              <div className={styles.capability} key={capability._key}>
                {capability.associatedTag ? (
                  <Link
                    className={styles.capabilityTitle}
                    to={`/work/tagged/${capability.associatedTag.slug.current}`}
                    onClick={() => setActiveTags([capability.associatedTag])}
                  >
                    <span className={cx("subheading3")}>
                      {capability.title}
                    </span>
                  </Link>
                ) : (
                  <span className={cx("subheading3", styles.capabilityTitle)}>
                    {capability.title}
                  </span>
                )}
                <p className={cx("body", styles.capabilityDetails)}>
                  {capability.details}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
