import React from "react";
import { graphql } from "gatsby";
import AboutPageTemplate from "src/templates/about-page";

export const query = graphql`
  query AboutQuery {
    page: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const AboutPage = props => {
  const { location, data } = props;
  const content = props.data.page.content;

  return <AboutPageTemplate location={location} pageContext={content} />;
};

export default AboutPage;
