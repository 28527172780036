import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { AboutIntroCarousel } from "src/components/about/intro-carousel";
import { AboutHowWhat } from "src/components/about/how-what";
import { AboutTeam } from "src/components/about/team";

const AboutPageTemplate = ({ location, pageContext }) => {
  const { main, meta } = pageContext;
  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} path={location.pathname} slug={main.slug.current} />
      <AboutIntroCarousel images={main.heroSlideshow} />
      <AboutHowWhat how={main.how} what={main.what} />
      <AboutTeam team={main.team} />
    </RevealOnMount>
  );
};

export default AboutPageTemplate;
